<template>
  <div class="w-full gap-2 justify-center">
    <fieldset class="mt-4 border-faint-grey">
      <legend>
        {{ $t('legenda') }}
      </legend>
      <vs-list>
        <vs-list-item
          title="${USER_NAME}"
          subtitle="Nome do usuário. Nome que constará no certificado."
        />
        <vs-list-item
          title="${VALIDATION_CODE}"
          subtitle="Código de validação do certificado, gerado automaticamente pelo sistema."
        />
        <vs-list-item
          title="${DATE_EMIT}"
          subtitle="Data de emissão do certificado."
        />
      </vs-list>
    </fieldset>
  </div>
</template>

<script>


export default {
  directives: {

  },
  components: {

  },
  props: {
    id: {
      default: null,
      type: Number
    }
  },

  data: () => ({

  }),
  computed: {

  },
  beforeMount() {

  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style>
.vs-list--header{
  display: inline !important;
}
</style>
