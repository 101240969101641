var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-full gap-2 justify-center" }, [
    _c(
      "fieldset",
      { staticClass: "mt-4 border-faint-grey" },
      [
        _c("legend", [_vm._v(" " + _vm._s(_vm.$t("legenda")) + " ")]),
        _c(
          "vs-list",
          [
            _c("vs-list-item", {
              attrs: {
                title: "${USER_NAME}",
                subtitle: "Nome do usuário. Nome que constará no certificado.",
              },
            }),
            _c("vs-list-item", {
              attrs: {
                title: "${VALIDATION_CODE}",
                subtitle:
                  "Código de validação do certificado, gerado automaticamente pelo sistema.",
              },
            }),
            _c("vs-list-item", {
              attrs: {
                title: "${DATE_EMIT}",
                subtitle: "Data de emissão do certificado.",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }